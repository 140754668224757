import logo from './assets/work-in-progress.gif';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <div>
          <h1>PlantIoT</h1>
          <p className='subtitle'>
            Tecnología que florece en tu hogar.
          </p>
        </div>

        <div>
          <img src={logo} className="work-progress" alt="work in progress..." />

          <div className='work-progress-container'> <p>
            Página en construcción
          </p>

            <div className='dots-container'>
              <p className='dots'>...</p></div>
          </div>

        </div>
      </header>

      <footer>
        <p>© 2024 PlantIoT. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;
